import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';

const Faq = ({ FaqText, Faqs }) => {
    if (!Faqs || Faqs.length === 0)
        return null;

    const [expandedFaq, setExpandedFaq] = useState();

    return <div className='faq'>
        <h3 className='heading2'>{FaqText}</h3>
        <div className='faqs'>
            {Faqs && Faqs.map((faq, index) => {
                return <div key={index} className="faqs__item">
                    <button onClick={() => { setExpandedFaq(expandedFaq?.Id === faq.Id ? null : faq) }} data-id={faq.Id} className="btn p-0 w-100 align-items-center toggleBtn d-flex justify-content-between"
                        type="button" data-bs-toggle="collapse" data-bs-target={`#${faq.Id}`} aria-controls={faq.Id}
                        aria-expanded="false">
                        <div>
                            <span className="heading5 d-block text-start">{faq.Question}</span>
                        </div>
                        <i className={`le-icon-chevron ${expandedFaq?.Id === faq.Id ? "rot-90" : "rot-270"}`}></i>
                    </button>

                    <div data-id={faq.Id} className={`item__answer collapse mt-5 ${expandedFaq?.Id === faq.Id ? "show" : ""}`}>
                        <p className="paragraph text-color--gray80 m-0"  dangerouslySetInnerHTML={{ __html: faq.Answer}}></p>
                    </div>
                </div>
            })}
        </div>
    </div>;
}
const root = createRoot(document.getElementById('REACT_faq'));
root.render(
    <Faq {..._THULEDATA._faq} />
);